<template>
  <PollResult :data="data" />
</template>

<script>
import PollResult from "./PollResult.vue"
import { mapGetters } from "vuex"

// The component connects the child component to the store
export default {
  name: "PollResultContainer",
  components: {
    PollResult
  },
  props: {
    mission: Object
  },
  computed: {
    ...mapGetters(["missions", "gameStatus"]),
    currentMission() {
      const activityId = this.mission?.activityId
      if (activityId) {
        return Object.values(this.missions).find(
          mission =>
            mission.activityId === activityId && mission.step === "play"
        )
      }

      return this.mission
    },
    isTrw() {
      return this.currentMission.trw
    },
    currentMissionID() {
      return this.currentMission?.id
    },
    // probably not the best way to gather the answers
    answers() {
      const MAX_NUMBER_OF_ANSWERS = 30
      var array = []
      if (!this.isTrw) {
        for (let i = 1; i < MAX_NUMBER_OF_ANSWERS; i++) {
          const answer = this.currentMission[`answer${i}`]
          if (answer) array.push(answer)
          else break
        }
      } else {
        array = this.gameStatus?.trwOptions ?? []
      }
      return array
    },
    // contans the number of submissions
    options() {
      return this.answers.map(answer => {
        const option = this.isTrw ? answer.title : answer

        const obj = this.missionPlaysArrayGroupedByAnswer[option]
        return {
          option,
          num: obj ? obj.length : 0
        }
      })
    },
    // the total number of submissions
    total() {
      return this.options.reduce((acc, val) => acc + val.num, 0)
    },
    missionPlaysArray() {
      return this.$store.getters.missionPlaysArray
    },
    missionPlaysArrayGroupedByAnswer() {
      return (
        this.missionPlaysArray
          // turn an array into a string
          .map(play => ({
            ...play,
            answer: play.answer[0]
          }))
          // group
          .reduce((acc, play) => {
            const title = play?.answer?.title

            if (title in acc) {
              acc[title].push(play)
            } else {
              acc[title] = [play]
            }
            return acc
          }, {})
      )
    },
    // make sure the plays belogn to the current mission
    data() {
      const total = this.total
      const instructions = this.currentMission?.instructions
      return this.options
        .map(obj => {
          const num = total ? (obj.num / total) * 100 : 0
          const percent = Math.round(num * 10) / 10
          return {
            ...obj,
            percent,
            instructions
          }
        })
        .sort((a, b) => b.percent - a.percent)
    }
  },
  methods: {
    dedupe(array) {
      return array.filter(({ show }) => show)
    }
  }
}
</script>
