<template>
  <v-flex
    pa-2
    ref="root"
    class="poll-result-wrap"
    :class="{ overflow: !playing }"
  >
    <v-layout
      column
      justify-center
      style="min-height: 100%; max-height: fit-content"
    >
      <div
        v-if="mission.createdByApi"
        class="poll-instructions"
        v-html="instructions"
      />
      <v-flex
        shrink
        v-for="(item, index) in options"
        :key="'poll-result-' + index"
        class="poll-result"
        :class="getRowClass(index)"
        @click="navigate(item.option)"
      >
        <v-layout row style="position: relative">
          <div
            class="percent-indicator"
            :class="item.percent > 90 ? 'over-90' : ''"
            :style="{ width: item.percent + '%' }"
          />
          <v-flex class="left-col">
            <v-layout row style="position: relative">
              <!-- Team rank -->
              <v-flex shrink d-flex align-center justify-center>
                <div class="poll-rank">
                  {{ index + 1 }}
                </div>
              </v-flex>
              <v-flex shrink d-flex text-xs-left align-center class="team-name">
                {{ item.option }}
              </v-flex>
            </v-layout>
          </v-flex>
          <!-- Team total-->
          <v-flex shrink class="v-align team-total">
            <div>{{ item.percent }}<span class="total-percent">%</span></div>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </v-flex>
</template>

<script>
import { mapGetters } from "vuex"
import NavigationService from "@/services/navigation.service"
import { Power3, TweenMax } from "gsap/TweenMax"
import { cloneDeep } from "lodash"
import MissionCollection from "@shared/MissionCollection"

export default {
  name: "PollResult",
  props: {
    data: Array
  },
  data() {
    return {
      options: [],
      playing: true
    }
  },
  computed: {
    ...mapGetters(["missions", "orgID", "gameID"]),
    isHost() {
      return this.$store.getters.user.role == "facilitator"
    },
    missionsArray() {
      return Object.values(this.missions)
    },
    mission() {
      return this.$store.getters.getCurrentMission
    },
    playStep() {
      return MissionCollection.normalize(this.$store.getters.missions).find(
        mission =>
          mission.activityId === this.mission.activityId && mission.play
      )
    },
    instructions() {
      return this.mission.activityId
        ? this.playStep.instructions
        : this.mission.instructions
    }
  },
  mounted() {
    // $nextTick because the component is wrapped
    // into <transition>
    this.$nextTick(() => {
      const collection = this.$refs.root.getElementsByClassName("poll-result")
      TweenMax.staggerFrom(
        collection,
        0.5,
        {
          y: 50,
          opacity: 0,
          delay: 0.3
        },
        0.2,
        () => {
          this.playing = false
        }
      )

      this.options = cloneDeep(this.data)

      this.options
        .filter(obj => obj.percent > 0)
        .forEach((option, index) => {
          TweenMax.from(option, 1, {
            percent: 0,
            decimal: 0,
            ease: Power3.easeOut,
            roundProps: "percent",
            delay: 0.5 + index * 0.2
          })
        })
    })
  },
  methods: {
    navigate(missionName) {
      if (!this.isTrw || !this.isHost) return
      var mission = this.missionsArray.filter(
        mission => mission.title == missionName
      )
      NavigationService.navigateTo(this.orgID, this.gameID, mission[0].id)
    },
    getRowClass(idx) {
      const isEqualtoFirst = this.data[0].percent === this.data[idx].percent
      return {
        clickable: this.isTrw,
        "poll-result-top": isEqualtoFirst,
        "poll-result-sub": !isEqualtoFirst
      }
    },
    isTrw() {
      return this.mission.trw
    }
  }
}
</script>

<style lang="scss">
.clickable {
  cursor: ne-resize;
}
.poll-result-wrap {
  color: #fff;
  overflow: hidden;
  &.overflow {
    overflow: auto;
  }

  .percent-indicator {
    top: 0;
    left: 0;
    position: absolute;
    width: 0%;
    height: 54px;
    margin-top: -6px;
    border-radius: 20px 0 0 20px;
    transition: background-color 0.5s !important;
    background-color: $color-grey-light5;
  }
  .over-90 {
    border-radius: 20px 20px 20px 20px !important;
  }
  .v-align {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .poll-result {
    background-image: radial-gradient(
      137.87% 137.87% at 49.24% 9.52%,
      #2c2c2c 0%,
      #000000 100%
    );
    background-position: center;
    background-size: 800px;
    height: 56px;
    padding-top: 6px;
    border: 1px solid rgba(255, 255, 255, 0.3);
    box-sizing: border-box;
    box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.32);
    border-radius: 20px;
    font-size: 1.2em;
    font-weight: bold;
  }

  .poll-instructions {
    background-image: radial-gradient(
      137.87% 137.87% at 49.24% 9.52%,
      #2c2c2c 0%,
      #000000 100%
    );
    border: 1px solid rgba(255, 255, 255, 0.3);
    font-size: 18px;
    margin-bottom: 4px;
    padding-bottom: 4px;
    padding-inline: 18px;
    color: $color-white;
    border-radius: 20px;
    line-height: 1.3;
    margin-bottom: 16px;

    p {
      margin: 0;
    }
  }

  .left-col {
    padding: 6px 12px;
    position: relative;
  }
  .poll-rank {
    width: 1.6em;
    height: 1.6em;
    line-height: 1.7em;
    border-radius: 3px;

    color: $color-white;
    font-weight: bold;
    margin-right: 15px;
    margin-left: 10px;
    font-size: 20px;
    margin-top: -3px;
  }
  .team-name {
    position: relative;
    font-size: 20px;
    line-height: 1em;
  }
  .team-total {
    position: relative;
    text-align: center;
    min-width: 3.5em;
    font-size: 22px;
  }
  .total-percent {
    font-size: 20px;

    position: relative;
    margin-left: 1px;
    margin-top: -3px;
  }
  .poll-result.poll-result-top .percent-indicator {
    background-color: #5aae59;
  }
  .poll-result.poll-result-sub .percent-indicator {
    background-color: #f5665d;
  }

  .poll-result:not(:last-child) {
    margin-bottom: 10px;
  }
  .breakout {
    color: $color-white;
    background-color: $primary_accent_color !important;
    border-radius: 8px;

    margin-top: -25px;
    margin-bottom: 10px;
  }
}
</style>
