var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-flex",
    {
      ref: "root",
      staticClass: "poll-result-wrap",
      class: { overflow: !_vm.playing },
      attrs: { "pa-2": "" },
    },
    [
      _c(
        "v-layout",
        {
          staticStyle: { "min-height": "100%", "max-height": "fit-content" },
          attrs: { column: "", "justify-center": "" },
        },
        [
          _vm.mission.createdByApi
            ? _c("div", {
                staticClass: "poll-instructions",
                domProps: { innerHTML: _vm._s(_vm.instructions) },
              })
            : _vm._e(),
          _vm._l(_vm.options, function (item, index) {
            return _c(
              "v-flex",
              {
                key: "poll-result-" + index,
                staticClass: "poll-result",
                class: _vm.getRowClass(index),
                attrs: { shrink: "" },
                on: {
                  click: function ($event) {
                    return _vm.navigate(item.option)
                  },
                },
              },
              [
                _c(
                  "v-layout",
                  { staticStyle: { position: "relative" }, attrs: { row: "" } },
                  [
                    _c("div", {
                      staticClass: "percent-indicator",
                      class: item.percent > 90 ? "over-90" : "",
                      style: { width: item.percent + "%" },
                    }),
                    _c(
                      "v-flex",
                      { staticClass: "left-col" },
                      [
                        _c(
                          "v-layout",
                          {
                            staticStyle: { position: "relative" },
                            attrs: { row: "" },
                          },
                          [
                            _c(
                              "v-flex",
                              {
                                attrs: {
                                  shrink: "",
                                  "d-flex": "",
                                  "align-center": "",
                                  "justify-center": "",
                                },
                              },
                              [
                                _c("div", { staticClass: "poll-rank" }, [
                                  _vm._v(" " + _vm._s(index + 1) + " "),
                                ]),
                              ]
                            ),
                            _c(
                              "v-flex",
                              {
                                staticClass: "team-name",
                                attrs: {
                                  shrink: "",
                                  "d-flex": "",
                                  "text-xs-left": "",
                                  "align-center": "",
                                },
                              },
                              [_vm._v(" " + _vm._s(item.option) + " ")]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "v-flex",
                      {
                        staticClass: "v-align team-total",
                        attrs: { shrink: "" },
                      },
                      [
                        _c("div", [
                          _vm._v(_vm._s(item.percent)),
                          _c("span", { staticClass: "total-percent" }, [
                            _vm._v("%"),
                          ]),
                        ]),
                      ]
                    ),
                  ],
                  1
                ),
              ],
              1
            )
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }